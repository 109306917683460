<template>
    <section class="baseInfo">
        <div style="border: 1px solid #bce8f1;border-radius: 2px;">
            <div style="color: #3a87ad;background-color: #d9edf7;padding: 10px 15px;border-bottom: 1px solid #bce8f1;border-radius: 2px 2px 0 0;">用户资料</div>
            <div style="padding: 15px;color: #717171;font-size: 13px">
                <div>
                    承租人类型: <span>{{tenantData.type}}</span>
                </div>
                <div>
                    姓名: <span>{{tenantData.name}}</span>
                </div>
                <div>
                    性别: <span>{{tenantData.sex?tenantData.sex:"女"}}</span>
                </div>
                <div>
                    手机号: <span>{{tenantData.tel1}}</span>
                </div>
                <div>
                    证件号码: <span>{{tenantData.idcard}}</span>
                </div>
                <div>
                    工作单位: <span>{{tenantData.workInfo?tenantData.workInfo:"暂无数据"}}</span>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
    export default {
        name: "baseInfo",
        props: {
            tenantData: {
                type: Object,
                default: () => ({})
            }
        },
        data() {
            return {
                formSearch: {}
            };
        },
        components:{  },
        async created() {

        },
        methods: {
        }
    };
</script>

<style scoped>

</style>